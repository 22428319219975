import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import PageHeader from "../components/PageHeader";
import CardRedemption from "./primepayComponents/CardRedemption";
import GeneralStats from "./dashboardComponents/GeneralStats";
import RedemptionHistory from "./primepayComponents/RedemptionHistory";
import axios from "axios";
import swal from "sweetalert";
import { useNavigate } from "react-router";
const PrimePay = () => {
  const [cardRemptionTable, setCardRedemptionTable] = useState([]);
  const [original, setOriginal] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [isProceedloading, setProceedLoading] = useState(false);
  const [isloading, setLoading] = useState(false);
  const [cal, setCal] = useState("");
  const handleClose = () => setShow(false);
  const [verifycode, setVerifyCode] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [show, setShow] = useState(false);
  const [val, setVal] = useState("");
  const navigate = useNavigate();

  let amount = Number(val);

  useEffect(() => {
    // var now = new Date();
    // setCal(now);
    if (cal === "") {
      axios
        .get(
          `/prime.sika/v1/merchant-accounts/redemptions?merchant_code=${localStorage.getItem(
            "company_code"
          )}&limit=10&page=${currentPage}`
        )
        .then((res) => {
          setLoading(true);
          setCardRedemptionTable(res.data.data.redemptions);
          setTotalPage(res.data.data.total_pages);
        })
        .catch((err) => {
          console.log(err.response);
        });
    } else {
      axios
        .get(
          `/prime.sika/v1/merchant-accounts/redemptions?merchant_code=${localStorage.getItem(
            "company_code"
          )}`
        )
        .then((res) => {
          setLoading(true);
          setOriginal(res.data.data.redemptions);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  }, [currentPage, cal]);

  const handleValidation = (e) => {
    setProceedLoading(true);
    if (verifycode.length !== 6) {
      setProceedLoading(false);
      setErrorMessage("Invalid Verification Code Entered");
    } else {
      setProceedLoading(true);
      axios
        .post("/prime.sika/v1/accounts/customer/redeem", {
          account_code: verificationCode,
          merchant_code: localStorage.getItem("company_code"),
          amount: amount,
          confirmation_code: verifycode,
        })
        .then((res) => {
          setProceedLoading(false);
          swal({
            title: "Success",
            text: res.data.data.account.message,
            icon: "success",
            button: "OK",
            confirmButtonColor: "#00b17d",
          }).then((isOkay) => {
            if (isOkay) {
              axios
                .get(
                  `/prime.sika/v1/merchant-accounts/redemptions?merchant_code=${localStorage.getItem(
                    "company_code"
                  )}&limit=10&page=${currentPage}`
                )
                .then((res) => {
                  setLoading(true);
                  setCardRedemptionTable(res.data.data.redemptions);
                  setTotalPage(res.data.data.total_pages);
                })
                .catch((err) => {
                  console.log(err.response);
                });
              handleClose();
              navigate("/dashboard");
            }
          });
        })
        .catch((err) => {
          setProceedLoading(false);
          console.log(err.response.data);
          swal({
            title: "Error",
            text: err.response.data.error_msg,
            icon: "error",
            button: "OK",
            confirmButtonColor: "#00b17d",
          }).then((isOkay) => {
            if (isOkay) {
              setShow(false);
            }
          });
        });
    }
  };
  return (
    <>
      <div className="main-wrapper">
        <Header />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <PageHeader />
            <div className="row">
              <div className="col-lg-8">
                <div className="row">
                  <GeneralStats />
                  <div className="col-lg-12">
                    <CardRedemption
                      handleClose={handleClose}
                      isProceedloading={isProceedloading}
                      setProceedLoading={setProceedLoading}
                      verifycode={verifycode}
                      setVerifyCode={setVerifyCode}
                      handleValidation={handleValidation}
                      verificationCode={verificationCode}
                      setVerificationCode={setVerificationCode}
                      setErrorMessage={setErrorMessage}
                      errorMessage={errorMessage}
                      amount={amount}
                      val={val}
                      setVal={setVal}
                      show={show}
                      setShow={setShow}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="row">
                  <div className="col-lg-12">
                    <div
                      id="carouselExampleControls"
                      className="carousel slide"
                      data-ride="carousel"
                    >
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <img
                            className="img-fluid gift-image-size1"
                            src="/assets/img/prime-pay-love.png"
                            alt="First slide"
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            className="img-fluid gift-image-size1"
                            src="/assets/img/prime-pay-ghana.png"
                            alt="Second slide"
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            className="img-fluid gift-image-size1"
                            src="/assets/img/prime-pay-image.png"
                            alt="Third slide"
                          />
                        </div>
                      </div>
                      <a
                        className="carousel-control-prev"
                        href="#carouselExampleControls"
                        role="button"
                        data-slide="prev"
                      >
                        <span
                          className="carousel-control-prev-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only">Previous</span>
                      </a>
                      <a
                        className="carousel-control-next"
                        href="#carouselExampleControls"
                        role="button"
                        data-slide="next"
                      >
                        <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only text-primary">Next</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <RedemptionHistory
                  original={original}
                  setOriginal={setOriginal}
                  setCardRedemptionTable={setCardRedemptionTable}
                  cardRemptionTable={cardRemptionTable}
                  setTotalPage={setTotalPage}
                  cal={cal}
                  setCal={setCal}
                  isloading={isloading}
                  totalPage={totalPage}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PrimePay;
